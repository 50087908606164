/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
new WOW().init();
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };


  jQuery('.carousel').carousel({
    interval: 2000
  });

  jQuery('#owl-slider').owlCarousel( {
    loop: true,
    center: true,
    items: 3,
    margin: 15,
    autoplay: true,
    dots:false,
    nav:true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    navText: ['<i class="arrow-icon left-arrow"></i>','<i class="arrow-icon right-arrow"></i>'],
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 3
      },
      1170: {
        items: 3
      }
    }
  });
  // Load Events
  $(document).ready(UTIL.loadEvents);

  $('#main-menu ul li').hover(function(){
      $(this).find('ul:first').css({visibility: "visible",display: "none"}).slideDown(400);
      },function(){
      $(this).find('ul:first').css({visibility: "hidden",display: "none"});
  });

  $("div#mobile-menu").append($("#main-menu").html());
  $("#mobile-menu > ul > li:has('ul') > a").before('<div class="menu-open"></div>');

  $('#menu').on("click", function(e) {
    $("html").toggleClass("no-scroll");
    $("body").toggleClass("no-scroll");
    $(".overlay-screen").toggleClass("is-visible");
    $(".mobile-menu").toggleClass("is-visible");
    e.preventDefault();
  });

  $(".overlay-screen").on("click", function(e) {
    $(this).removeClass("is-visible");
    $("html").removeClass("no-scroll");
    $("body").removeClass("no-scroll");
    $(".mobile-menu").removeClass("is-visible");
    e.preventDefault();
  });
   
  $(".menu-open").click(function() {
    $(this).parent().children('ul.sub-menu').slideToggle();
    $('.menu-open').not(this).parent().children('ul.sub-menu').slideUp();
    $(this).toggleClass('more');
    $('.menu-open').not(this).removeClass('more');
  });

  $(".back-top").hide(); 
  
  
  $(window).scroll(function () {
   if ($(this).scrollTop() > 200) {
    $('.back-top').fadeIn();
    $('.header-main').addClass("is-sticky");
    } else {
      $('.back-top').fadeOut();
      $('.header-main').removeClass("is-sticky");
    }
  });

  $('.back-top').click(function () {
    $('body,html').animate({scrollTop: 0}, 800);
    return false;
  });

  $('.lightgallery').lightGallery({
    thumbnail:false
  });

})(jQuery); // Fully reference jQuery after this point.
